import React, { useEffect, useState } from 'react';
import { Layout, Menu, Table, Button, Modal, Form, Input, Select, message, InputNumber, Tag } from 'antd';
import { getUsers, deleteUser } from '../api/users';
import { getBooks, createBook, updateBook, deleteBook } from '../api/books';
import { getOrders, updateOrderStatus } from '../api/orders';
import { observer } from 'mobx-react-lite';
import { authStore } from '../stores/authStore'; 
import { useNavigate } from 'react-router-dom';
import { register } from '../api/auth';

const { Header, Content, Footer, Sider } = Layout;

const AdminDashboard = observer(() => {
  const [selectedMenu, setSelectedMenu] = useState('users');
  const [users, setUsers] = useState([]);
  const [books, setBooks] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBookModalVisible, setIsBookModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [bookForm] = Form.useForm();
  const [editingBook, setEditingBook] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedMenu === 'users') fetchUsers();
    if (selectedMenu === 'books') fetchBooks();
    if (selectedMenu === 'orders') fetchOrders();
  }, [selectedMenu]);

  const fetchUsers = async () => {
    setLoading(true);
    const response = await getUsers();
    setUsers(response.data);
    setLoading(false);
  };

  const fetchBooks = async () => {
    setLoading(true);
    const response = await getBooks();
    setBooks(response.data);
    setLoading(false);
  };

  const fetchOrders = async () => {
    setLoading(true);
    const response = await getOrders();
    setOrders(response.data);
    setLoading(false);
  };

  const handleCreateUser = async (values) => {
    await register(values);
    message.success('User created successfully');
    setIsModalVisible(false);
    fetchUsers();
  };

  const handleDeleteUser = async (id) => {
    await deleteUser(id);
    message.success('User deleted successfully');
    fetchUsers();
  };

  const getStatusTag = (status) => {
    switch (status) {
      case 'In Processing':
        return <Tag color="yellow">In Processing</Tag>;
      case 'Accepted':
        return <Tag color="blue">Accepted</Tag>;
      case 'Delivered':
        return <Tag color="green">Delivered</Tag>;
      case 'Canceled':
        return <Tag color="red">Canceled</Tag>;
      default:
        return <Tag color="gray">{status}</Tag>;
    }
  };
  
  const handleCreateOrUpdateBook = async (values) => {
    if (editingBook) {
      await updateBook(editingBook.id, values);
      message.success('Book updated successfully');
    } else {
      await createBook(values);
      message.success('Book created successfully');
    }
    setIsBookModalVisible(false);
    fetchBooks();
  };

  const handleDeleteBook = async (id) => {
    await deleteBook(id);
    message.success('Book deleted successfully');
    fetchBooks();
  };

  const handleUpdateOrderStatus = async (id, status) => {
    await updateOrderStatus(id, status);
    message.success('Order status updated');
    fetchOrders();
  };

  const userColumns = [
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button style={{backgroundColor:'red', color:'white'}} type="danger" onClick={() => handleDeleteUser(record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  const bookColumns = [
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Author', dataIndex: 'author', key: 'author' },
    { title: 'Cost', dataIndex: 'cost', key: 'cost' },
    { title: 'Page Number', dataIndex: 'pageNum', key: 'pageNum' }, 
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button
            type="primary"
            onClick={() => {
              setEditingBook(record);
              bookForm.setFieldsValue(record);
              setIsBookModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button type="danger" onClick={() => handleDeleteBook(record.id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const orderColumns = [
    { title: 'Order ID', dataIndex: 'id', key: 'id' },
    { 
      title: 'Status', 
      dataIndex: 'status', 
      key: 'status',
      render: (status) => getStatusTag(status),  
    },
    { title: 'Total Cost', dataIndex: 'totalCost', key: 'totalCost' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Select
          defaultValue={record.status}
          onChange={(status) => handleUpdateOrderStatus(record.id, status)}
        >
          <Select.Option value="In Processing">In Processing</Select.Option>
          <Select.Option value="Accepted">Accepted</Select.Option>
          <Select.Option value="Delivered">Delivered</Select.Option>
          <Select.Option value="Canceled">Canceled</Select.Option>
        </Select>
      ),
    },
  ];

  const handleLogout = () => {
    authStore.logout(); 
    message.success('Logged out successfully');
    navigate('/');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <Menu theme="dark" mode="inline" selectedKeys={[selectedMenu]} onClick={(e) => setSelectedMenu(e.key)}>
          <Menu.Item key="users">Users</Menu.Item>
          <Menu.Item key="books">Books</Menu.Item>
          <Menu.Item key="orders">Orders</Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Admin Panel
          <Button type="primary" onClick={handleLogout}>
            Logout
          </Button>
        </Header>
        <Content style={{ padding: '50px' }}>
          {selectedMenu === 'users' && (
            <>
              <Button type="primary" onClick={() => setIsModalVisible(true)}>
                Add New User
              </Button>
              <Table dataSource={users} columns={userColumns} loading={loading} rowKey="id" />
            </>
          )}
          {selectedMenu === 'books' && (
            <>
              <Button type="primary" onClick={() => setIsBookModalVisible(true)}>
                Add New Book
              </Button>
              <Table dataSource={books} columns={bookColumns} loading={loading} rowKey="id" />
            </>
          )}
          {selectedMenu === 'orders' && <Table dataSource={orders} columns={orderColumns} loading={loading} rowKey="id" />}
        </Content>
        <Footer>©2024 Admin Panel</Footer>

      
        <Modal
          title="Add New User"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={() => form.submit()}
        >
          <Form form={form} onFinish={handleCreateUser} layout="vertical">
            <Form.Item name="email" label="Email" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>

       
        <Modal
          title={editingBook ? 'Edit Book' : 'Add New Book'}
          visible={isBookModalVisible}
          onCancel={() => {
            setIsBookModalVisible(false);
            setEditingBook(null);
            bookForm.resetFields();
          }}
          onOk={() => bookForm.submit()}
        >
          <Form form={bookForm} onFinish={handleCreateOrUpdateBook} layout="vertical">
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="author" label="Author" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="cost" label="Cost" rules={[{ required: true }]}>
              <Input type="number" />
            </Form.Item>
            <Form.Item name="isbn" label="ISBN" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="pageNum" label="Page Number" rules={[{ required: true }]}>
              <InputNumber min={1} style={{ width: '100%' }}/>
            </Form.Item>
          </Form>
        </Modal>
      </Layout>
    </Layout>
  );
});

export default AdminDashboard;
