import React, { useEffect, useState } from 'react';
import { Layout, Table, Button, message, Input, Space } from 'antd';
import { getBooks } from '../api/books';
import { createOrder } from '../api/orders';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { authStore } from '../stores/authStore'; 

const { Header, Content, Footer } = Layout;
const { Search } = Input;

const UserDashboard = observer(() => {
  const [books, setBooks] = useState([]);
  const [shoppingBag, setShoppingBag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    setLoading(true);
    const response = await getBooks();
    setBooks(response.data);
    setFilteredBooks(response.data);
    setLoading(false);
  };

  const addToBag = (book) => {
    const existingBook = shoppingBag.find((item) => item.id === book.id);
    if (existingBook) {
      existingBook.quantity += 1;
      setShoppingBag([...shoppingBag]);
    } else {
      setShoppingBag([...shoppingBag, { ...book, quantity: 1 }]);
    }
    message.success(`${book.title} added to your shopping bag`);
  };

  const removeFromBag = (book) => {
    const updatedBag = shoppingBag.map((item) =>
      item.id === book.id
        ? { ...item, quantity: item.quantity - 1 }
        : item
    ).filter((item) => item.quantity > 0);
    setShoppingBag(updatedBag);
    message.success(`${book.title} updated in your shopping bag`);
  };

  const handleSubmitOrder = async () => {
    const bookList = shoppingBag.map((book) => ({
      bookId: book.id, 
      quantity: book.quantity,
    }));
    const totalCost = shoppingBag.reduce((acc, book) => acc + book.cost * book.quantity, 0);
    await createOrder({ bookList, totalCost });
    message.success('Order submitted successfully');
    setShoppingBag([]);
    navigate('/orders'); 
  };

  const handleLogout = () => {
    authStore.logout(); 
    message.success('Logged out successfully');
    navigate('/'); 
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredBooks(
      books.filter((book) =>
        book.title.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const bookColumns = [
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Author', dataIndex: 'author', key: 'author' },
    { title: 'Cost', dataIndex: 'cost', key: 'cost' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="primary" onClick={() => addToBag(record)}>
          Add to Bag
        </Button>
      ),
    },
  ];

  const bagColumns = [
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Author', dataIndex: 'author', key: 'author' },
    { title: 'Cost', dataIndex: 'cost', key: 'cost' },
    { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="danger" onClick={() => removeFromBag(record)}>
          Remove
        </Button>
      ),
    },
  ];

  const totalCost = shoppingBag.reduce((acc, book) => acc + book.cost * book.quantity, 0);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header>
        <div style={{ float: 'left' }}>
          <Button style={{color:'dodgerblue', textDecorationLine:'underline'}} type="link" onClick={() => navigate('/user-dashboard')}>
            User Dashboard
          </Button>
          <Button style={{color:'white'}} type="link" onClick={() => navigate('/orders')}>
            Orders
          </Button>
        </div>
        <Button style={{backgroundColor:'red', color:'white'}} type="danger" onClick={handleLogout}>
          Logout
        </Button>
      </Header>
      <Content style={{ padding: '50px' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Search
            placeholder="Search books by ISBN Number"
            enterButton
            value={searchText}
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Table
            dataSource={filteredBooks}
            columns={bookColumns}
            rowKey="id"
            loading={loading}
            pagination={false}
          />
          <h2>Your Shopping Bag</h2>
          <Table
            dataSource={shoppingBag}
            columns={bagColumns}
            rowKey="id"
            pagination={false}
          />
          <div style={{ marginTop: '16px' }}>
            <Button type="primary" onClick={handleSubmitOrder}>
              Confirm Order - Total Cost: ${totalCost.toFixed(2)}
            </Button>
          </div>
        </Space>
      </Content>
      <Footer style={{ textAlign: 'center' }}>BookStore ©2024</Footer>
    </Layout>
  );
});

export default UserDashboard;
