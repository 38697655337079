import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { login } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import { authStore } from '../stores/authStore';

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoading(true);
    try {
        const response = await login(values);
        message.success('Login successful');
        localStorage.setItem('token', response.data.access_token);
        const userRole = response.data.role;
        console.log(userRole)
        if (userRole === 'Admin') {
            navigate('/admin');
        } else {
            navigate('/user');
        }
    } catch (error) {
        message.error('Login failed, please check your credentials');
    }
    setLoading(false);
  };

  return (
    <div style={{ width: '300px', margin: 'auto', padding: '50px' }}>
      <h2>Login</h2>
      <Form onFinish={handleLogin}>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
