import { makeAutoObservable } from 'mobx';
import { login, register } from '../api/auth';
import axios from 'axios';

class AuthStore {
  user = null;
  token = localStorage.getItem('token') || null;
  isAdmin = false;

  constructor() {
    makeAutoObservable(this);
  }

  async login(email, password) {
    const response = await login({ email, password });
    this.token = response.data.access_token;
    localStorage.setItem('token', this.token);
    const id = response.data.sub;
    this.user = [response.data,...id];
    this.isAdmin = this.user.role === 'Admin'; 
    await this.fetchUserProfile(response.data.sub); 
  }

  async register(email, password) {
    const response = await register({ email, password });
    this.token = response.data.access_token;
    localStorage.setItem('token', this.token);
    
  }

  async fetchUserProfile(id) {
    const response = await axios.get(`/users/${id}`, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
    this.user = response.data;
    this.isAdmin = this.user.role === 'Admin';  
  }

  setToken(token) {
    this.token = token;
    localStorage.setItem('token', token);
  }

  logout() {
    this.user = null;
    this.token = null;
    this.isAdmin = false;
    localStorage.removeItem('token');
  }
}

export const authStore = new AuthStore();
