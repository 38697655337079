import api from '../axiosConfig';
import { authStore } from '../stores/authStore';

export const login = async (loginData) => {
    const response = await api.post('/auth/login', loginData); 
    const { access_token } = response.data;

    authStore.setToken(access_token);

    return response;
};
export const register = (registerData) => api.post('/auth/register', registerData);
