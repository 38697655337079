import React, { useEffect, useState } from 'react';
import { Layout, Table, Button, message, Tag, Modal } from 'antd';
import { getMyOrders } from '../api/orders'; 
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom'; 
import { authStore } from '../stores/authStore';

const { Header, Content, Footer } = Layout;

const OrderPage = observer(() => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null); 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await getMyOrders(); 
      setOrders(response.data);  
    } catch (error) {
      message.error('Failed to fetch orders');
    }
    setLoading(false);
  };

  const showOrderDetails = (order) => {
    setSelectedOrder(order);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedOrder(null);
  };

  const getStatusTag = (status) => {
    switch (status) {
      case 'In Processing':
        return <Tag color="yellow">In Processing</Tag>;
      case 'Accepted':
        return <Tag color="blue">Accepted</Tag>;
      case 'Delivered':
        return <Tag color="green">Delivered</Tag>;
      case 'Canceled':
        return <Tag color="red">Canceled</Tag>;
      default:
        return <Tag color="gray">{status}</Tag>;
    }
  };
  
  const orderColumns = [
    { title: 'Order ID', dataIndex: 'id', key: 'id' },
    { 
      title: 'Status', 
      dataIndex: 'status', 
      key: 'status', 
      render: (status) => getStatusTag(status),
    },
    { 
      title: 'Total Cost', 
      dataIndex: 'totalCost', 
      key: 'totalCost', 
      render: (totalCost) => `€${parseInt(totalCost).toFixed(2)}`,
    },
    { 
      title: 'Date', 
      dataIndex: 'creationDate', 
      key: 'creationDate', 
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Details',
      key: 'details',
      render: (_, record) => (
        <Button onClick={() => showOrderDetails(record)}>View Details</Button>
      ),
    },
  ];

  const handleLogout = () => {
    authStore.logout(); 
    message.success('Logged out successfully');
    navigate('/'); 
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header>
        <Button style={{color:'white'}} type="link" onClick={() => navigate('/user')}>
          User Dashboard
        </Button>
        <Button style={{color:'dodgerblue', textDecorationLine:'underline'}} type="link" onClick={() => navigate('/orders')}>
          Orders
        </Button>
        <Button style={{backgroundColor:'red', color:'white'}} type="danger" onClick={() => handleLogout()}>
          Logout
        </Button>
      </Header>
      <Content style={{ padding: '50px' }}>
        <h2>Your Orders</h2>
        <Table
          dataSource={orders}
          columns={orderColumns}
          rowKey="id"
          loading={loading}
          pagination={false}
        />

      
        {selectedOrder && (
          <Modal
            title={`Order Details (ID: ${selectedOrder.id})`}
            visible={isModalVisible}
            onCancel={handleModalClose}
            footer={[
              <Button key="close" onClick={handleModalClose}>
                Close
              </Button>,
            ]}
          >
            <p><strong>Status:</strong> {getStatusTag(selectedOrder.status)}</p>
            <p><strong>Total Cost:</strong> €{parseInt(selectedOrder.totalCost).toFixed(2)}</p>
            <p><strong>Ordered Date:</strong> {new Date(selectedOrder.creationDate).toLocaleDateString()}</p>
            
            <h3>Books Ordered:</h3>
            <ul>
              {selectedOrder.orderBooks.map((orderBook) => (
                <li key={orderBook.id}>
                  {orderBook.book.title} - Quantity: {orderBook.quantity}
                </li>
              ))}
            </ul>
          </Modal>
        )}
      </Content>
      <Footer style={{ textAlign: 'center' }}>BookStore ©2024</Footer>
    </Layout>
  );
});

export default OrderPage;
